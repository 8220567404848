

body {
  margin: 0;
  font-family: 'Poppins',
    sans-serif;
  background: linear-gradient(#c0f4d750, #77eec295)
}

.container {
  width: 100%;
  max-width: 720px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
}
.description-text {
  font-size: 23px;
  line-height: 28px;
  color: #0e6952;
}
@media (max-width: 720px) {
  .description-text {
    font-size: 20px !important;
  }
}
form {
  width: 100%;
  position: relative;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 30px;
}
.text-input {
  height: 36px;
  padding: 0 16px;
  border: 2px solid #07c36f;
  background: white;
  border-radius: 36px;
  font-size: 16px;
  color: #0e6952;
}
.error-message {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 30px;
  color: red;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.error {
  border-color: red !important;
  color: red !important;
}
.cta-button {
  height: 36px;
  padding: 0 16px;
  background: #07c36f;
  color: white;
  text-transform: uppercase;
  border: 2px solid #07c36f;
  border-radius: 36px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}
.success-wrapper {
  margin-top: 20px;
  border: 2px solid #07c36f80;
  border-radius: 12px;
  padding: 8px 18px;
  background: white;
  color: #0e6952;
}

.cta-button:disabled {
  cursor: no-drop;
  opacity: .5;
}